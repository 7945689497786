<template>
  <div>
    <div v-if="loading">加载中...</div>
    <div v-else>

      <div class="level-box" v-for="(item,index) in topad" :key="'djd'+index" @click="jumpUrl(item)" style="background-image: linear-gradient(90deg,#28bda8, #464548);color:#fff;margin-top:-1px;padding:7px 10px">
        <img class="level-pic" :src="item.picname">
        <div class="mid-area" style="line-height: 28px">
          <div class="level-title" style="font-size:20px">{{item.title}}</div>
          <div class="level-sub" style="font-size:11px">{{item.content}}</div>
        </div>
        <div style="width:122px;margin-left:auto">
          <div class="bottom-btn" @click="download(item)">立即进入</div>
        </div>
      </div>
      <el-carousel v-if="sorts.banner && sorts.banner.show"  height="170px">
        <el-carousel-item v-for="(item,index) in bannerData" :key="'bann'+index">
          <div style="width:100%;height:100%" @click="jumpUrl(item,1)">
            <img style="width:100%;height:100%" :src="item.picname">
          </div>
        </el-carousel-item>
      </el-carousel>

      <div class="level-box float-btm-box" v-for="(item,index) in bottomad" :key="'yij'+index" @click="jumpUrl(item)" style="background-image: linear-gradient(90deg,#28bda8, #464548);color:#fff;padding:7px 10px">
        <img class="level-pic" :src="item.picname">
        <div class="mid-area" style="line-height: 28px">
          <div class="level-title" style="font-size:20px">{{item.title}}</div>
          <div class="level-sub" style="font-size:11px">{{item.content}}</div>
        </div>
        <div style="width:122px;margin-left:auto">
          <div class="bottom-btn" @click="download(item)">立即进入</div>
        </div>
      </div>
      <marquee-component v-if="sorts.notice && sorts.notice.show" :lists="listData" style="line-height: 50px"></marquee-component>
     <div v-if="sorts.apps && sorts.apps.show" >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="热门推荐" name="0"></el-tab-pane>
        <el-tab-pane label="涩情APP" name="1"></el-tab-pane>
        <el-tab-pane label="涩情直播" name="2"></el-tab-pane>
        <el-tab-pane label="赚钱娱乐" name="3"></el-tab-pane>
        <!-- <draggable v-model="tabs" :options="{animation: 200}">
          <el-tab-pane v-for="(tab, index) in tabs" :key="index" :label="tab.label" :name="tab.name">
            {{ tab.content }}
          </el-tab-pane>
        </draggable> -->
      </el-tabs>
      <div class="app-list">
        <div class="app-item" v-for="(item,index) in didData" :key="'erj'+index" @click="jumpUrl(item)">
          <img style="width:100%;height: 65px;border-radius: 12px" :src="item.picname">
          <div style="white-space: nowrap">{{item.title}}</div>
          <div class="down"  @click="download(item)">下载</div>
        </div>
      </div>
     </div>
      <div v-if="sorts.download && sorts.download.show" >
      <div class="level-box" v-for="(item,index) in five" :key="'sj'+index" @click="jumpUrl(item)">
        <img class="level-pic" :src="item.picname">
        <div class="mid-area">
          <div class="level-title">{{item.title}}</div>
          <div class="level-sub">下载次数：{{item.count}}</div>
        </div>
        <div style="width:122px;margin-left:auto">
          <div class="down"  @click="download(item)">下载</div>
        </div>
      </div>
      </div>
      <div style="margin-bottom:70px" v-if="sorts.chicken && sorts.chicken.show" >
      <div class="between-box" v-for="(item,index) in six" :key="'sjd'+index" @click="jumpUrl(item)">
        <img class="between-pic" :src="item.picname">
        <div class="mid-area-2">
          <div>
            <div class="certi">官方认证</div>
          </div>
          <div class="between-title" v-for="(e, idex) in item.content" :key="'wjd'+idex">{{e}}</div>
          <div class="between-sub">{{item.title}}</div>
          <div class="appoint"  @click="download(e)">我要约她</div>
        </div>
      </div>
      </div>
    </div>
    <!-- 在这里处理并显示你的数据 -->
  </div>
</template>

<script>
import axios from 'axios'
import MarqueeComponent from "@/components/marquee.vue";
import CryptoJS from 'crypto-js';
export default {
  components: {MarqueeComponent},
  data() {
    return {
      loading: true,
      activeName: 0,
      basicData:[],
      bannerData:[],
      ip:'',
      system:'',
      appId:'',
      language:'',
      hash:'',
      browser:'',
      sorts:[],
      didData:[],
      topad:[],
      bottomad:[],
      first:[],
      six:[],
      five:[],
      classOption: {
        limitMoveNum: 2,
        direction: 3,
      },

      listData: ['1231312312312321', '1231312312312321','1231312312312321'],
      data: null
    }
  },
  async created() {
    let url = new URL(window.location.href);
    let domain = url.hostname;
    this.hash = CryptoJS.MD5(domain).toString().slice(0,6);
    console.log('domain=>',domain)
    console.log('hash=>',this.hash)
    this.getDevice()
    this.getData()
    this.getbanner()
    this.getInfo()
    this.getSort()
  },
  // watch:{
  //   activeName(e){
  //     let ant = Number(e)+1;
  //     this.didData = this.basicData.filter(item => item.type === ant.toString());
  //   },
  // },

  methods:{
    getDevice(){
      // 获取操作系统
      this.system = navigator.platform;

      // 获取语言
      this.language = navigator.language || navigator.userLanguage;

      // 获取浏览器信息
      this.browser = navigator.userAgent;

      // 获取 IP 地址
      axios.get('https://api.ipify.org?format=json')
          .then(response => {
            this.ip = response.data.ip;
          })
          .catch(error => {
            console.error(error);
          });

      // 假设你已经从服务器获取了 appId
      this.appId = 'some-app-id-from-server';
    },
    jumpUrl(item,idx=0){
      if (idx == 1) {
        window.open(item.link);
        this.download2(item);
      } else {
        window.open(item.link);
        this.download(item);
      }
    },
    handleClick(tab, event) {
      console.log(tab, event, this.activeName);
      this.didData = [];
      let ant = Number(this.activeName)+1;
      this.didData = this.basicData.filter(item => item.type === ant.toString());
    },
    async getData(){
      try {
        const response = await axios.get('https://api.dmjtdq.com/index/index/app?hash='+this.hash, {
        });
        this.basicData = response.data.apps;
        this.didData = this.basicData.filter(item => item.type === "1");
        console.log('222=>',this.didData);
        this.first = this.basicData.filter(item => item.type === "1");
        this.five = this.basicData.filter(item => item.type === "5");
        this.six = this.basicData.filter(item => item.type === "6");
        this.topad = this.basicData.filter(item => item.type === "7");
        this.bottomad = this.basicData.filter(item => item.type === "8");
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async getSort(){
      try {
        const response = await axios.get('https://api.dmjtdq.com/index/index/layout?hash='+this.hash, {
        });
        this.sorts = response.data.layout;
        console.log('see=>',this.sorts)
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async getbanner(){
      try {
        const response = await axios.get('https://api.dmjtdq.com/index/index/banner?hash='+this.hash, {
        });
        this.bannerData = response.data.banners;
        console.log('see=>',this.bannerData)
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async download(rews) {
      try {
        const formData = new FormData();
        formData.append('ip', this.ip);
        formData.append('system', this.system);
        formData.append('app_id', rews.id);
        formData.append('language', this.language);
        formData.append('browser', this.browser);
        formData.append('hash', this.hash);

        const response = await axios.post('https://api.dmjtdq.com/index/index/download', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        // if(response){
        //   window.open(e.link)
        // }
        console.log('see=>',this.bannerData)
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async download2(e) {
      try {
        const formData = new FormData();
        formData.append('ip', this.ip);
        formData.append('system', this.system);
        formData.append('app_id', e.id);
        formData.append('language', this.language);
        formData.append('browser', this.browser);
        formData.append('hash', this.hash);

        const response = await axios.post('https://api.dmjtdq.com/index/index/click', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        // if(response){
        //   window.open(e.link)
        // }
        console.log('see=>',this.bannerData)
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async getInfo(){
      try {
        const response = await axios.get('https://api.dmjtdq.com/index/index/notice?hash='+this.hash, {
        });
        this.listData = response.data.notices;
        console.log('see=>',this.bannerData)
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.top-info{
  font-size: 0.1rem;
  color: red;
  text-align: center;
  display: inline-block;
  width: 100%;
  text-align: center;
  border: 1px solid;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-bottom:10px;
}
.app-list{
  display: flex;
  flex-flow: wrap;
}
.app-item{
  display: inline-block;
  margin:0 1%;
  width:18%;
}
::v-deep .el-tabs__item{
  font-size:16px;
  font-weight: bold;
  width: 25%;
  padding-right:0px;
}
::v-deep .el-tabs__nav{
  width: 100%;
}
::v-deep .el-tabs__item.is-active{
  width: 25%;
  font-size:16px;
  font-weight: bold;
  color:#fe562a;
}
::v-deep .el-tabs__active-bar{
  background-color:#fe562a;
  background-image: -webkit-linear-gradient(left, #35dcc5, #b64cbb);
  transform: translateX(0px);
  height: 5px;
  border-radius: 10px;
}
.marquee-b {
  display: flex;
  align-items: center;
  padding: 5px;
  background: #fef0f0;
  color: #fd4c5d;
  box-sizing: content-box;
}.marquee-b .marquee-svg {
   fill: #fd4c5d;
 }.marquee-b .btn-cc {
    padding: 3px 6px;
    white-space: nowrap;
    letter-spacing: .02rem;
    margin-left: 0.1rem;
    font-size: .2rem;
    text-align: center;
    border: 1px solid #fd4c5d;
    color: #fd4c5d;
    border-radius: 4px;
  }
.marquee-b .marquee-box {
  flex: 1;
  overflow: hidden;
  text-align: left;
  padding-left:10px;
}
.marquee-b .marquee-box p {
  display: inline-block;
  font-size: .24rem;
  line-height: .4rem;
}
.down {
  width: 100%;
  background-size: contain;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  border-radius: 30px;
  margin: 5px auto 18px;
  color: #00937e;
  border: 1px solid #00937e;
  font-size: 14px;
  text-align: center;
}
.appoint {
  width: 100%;
  font-size: 14px;
  color: #000;
  background-image: linear-gradient(to bottom, #e1c5aa, #fae6cc);
  font-weight: 700;
  text-align: center;
  border-radius: 30px;
  padding: 5px 0;
}
.level-box{
  border-top:1px solid #E5E5E5;
  padding: 12px 10px 10px;
  display: flex;
  border-bottom:1px solid #E5E5E5;
}
.level-pic{
  border-radius: 8px;
  display: inline-block;
  height:50px;
  width: 50px;
}
.level-title{
  font-size:17px;
  font-weight: bold;

}
.level-sub{
  font-size:11px;

}

.between-box{
  border-top:1px solid #E5E5E5;
  padding: 12px 10px 10px;
  display: flex;
  border-bottom:1px solid #E5E5E5;
}
.between-pic{
  border-radius: 16px;
  display: inline-block;
  height:100px;
  width: 50%;
}
.between-title{
  font-size: 12px;
  color: #333;
  display: inline-block;
  margin-right: 5px;
  background-color: #fae7ce;
  padding:0 5px;
  border-radius: 6px;

}
.between-sub{
  font-size:11px;

}
.mid-area{
  width: 100%;
  text-align: left;
  line-height: 25px;
  margin-left:10px;
}
.mid-area-2{
  width: 100%;
  text-align: left;
  line-height: 22px;
  margin-left:10px;
}
.certi{
  display: inline-block;
  padding:0 10px;
  color: #875e44;
  background-image: linear-gradient(to bottom, #e1c5aa, #fae6cc);
  border-radius: 10px 0;
  font-size: .12rem;
}.bottom-btn {
   position: absolute;
   top: 12px;
   width: 120px;
   right: 20px;
   height: 35px;
   line-height: 35px;
   color: #fff;
   background: linear-gradient(90deg, #ef54cd, #f2b90d);
   border-radius: 55px;
   text-align: center;
   font-size: 18px;
   padding: 0 5px;
   font-weight: bold;
 }
.float-btm-box{
  position: fixed;
  bottom:0;
  margin:0;
  right:0;
  left:0;
  z-index: 999;
}
</style>
